/**
 * @description 随机生成一串32位字符串
 * @param {Object} routeItem 路由对象
 * @param {Object} vm Vue实例
 */
 export const GetRandomStr = () => {
  const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678'
  const maxPos = $chars.length
  var pwd = ''
  for (var i = 0; i < 32; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  console.log(pwd)
  return pwd
}

/**
 * @description 初始化本地的基本信息-缓存
 * @param {Object} routeItem 路由对象
 * @param {Object} vm Vue实例
 */
 export const InitCacheLocalInfo = (state) => {
  console.log(state)
  state.token = ''
  state.openid = ''
  state.isgettoken = false
  state.outid = ''
  state.name = ''
  state.wxinfo = ''
  state.isbind = false
  state.appname = ''
  state.accesstoken = ''
  state.validdt = ''
  state.lasttoken = ''
  state.loginstatus = 0
  /*
    isgettoken: false, // 是否获取了token信息
    openid: '', // 这里会等于accesstoken为了兼容性
    name: '',
    outid: '',
    wxinfo: '',
    validdt: '', // 有效期，超过了时间需要再次申请openid
    isbind: false, // 是否绑定一卡通信息
    loginstatus: 0 // 状态信息，0是默认的啥也没有，1有localtoken没有送服务器，2送了服务器也有返回信息
  */
}
/**
 * 获取本地相关信息，比如localtoken，服务器token，有效期等信息
 */

 export const GetLocalTokenInfo = (state) => {
  // 获取token信息
  console.log('开始获取cache信息')
  InitCacheLocalInfo(state)
  state.token = localStorage.getItem('token')
  state.openid = localStorage.getItem('openid')
  state.isgettoken = localStorage.getItem('isgettoken')
  state.outid = localStorage.getItem('outid')
  state.name = localStorage.getItem('name')
  state.wxinfo = localStorage.getItem('wxinfo')
  state.isbind = localStorage.getItem('isbind')
  state.appname = localStorage.getItem('appname')
  state.accesstoken = localStorage.getItem('accesstoken')
  state.validdt = localStorage.getItem('validdt')
  state.accesstokendt = localStorage.getItem('accesstokendt')
  state.loginstatus = localStorage.getItem('loginstatus')
  state.bgpic = localStorage.getItem('bgpic')
  console.log('获取到的tokenshi' + state.token)
  if (IsBlank(state.token)) {
    console.log('重新获取token')
    state.token = NewLocalToken(state)
    state.loginstatus = 1
  }
  if (IsBlank(state.bgpic)) {
    state.bgpic = require(`../../../assets/yktbg.png`)
  }
  if (IsBlank(state.headpic)) {
    state.headpic = require(`../../../assets/mypic1.png`)
  }
  state.isgettoken = true
  IsReAuthLogin(state)
  console.log('获取完毕')
}

/**
 * 判断是否要重新验证及登录微信
 */
 export const IsReAuthLogin  = (state) => {
  state.isneedauthlogin = true
  // 获取token信息
  console.log('当前state信息：', state)
  if (IsBlank(state.token)) return true // 如果没有token肯定需要重新来
  console.log('token存在')
  if (IsBlank(state.accesstoken)) return true // 如果没有accesstoken肯定需要重新来
  console.log('accesstoken存在')
  console.log('有效期由后台控制，这里不做判断了')
  /*
  console.log(state.accesstokendt, IsBlank(state.accesstokendt))
  if (IsBlank(state.accesstokendt)) return true // 如果没有validdt肯定需要重新来
  console.log('accesstokendt存在' + state.accesstokendt)
  if (isValidDate(state.accesstokendt)) return true // 如果没有validdt肯定需要重新来
  console.log('accesstokendt合法')
  // console.log(parseTime(state.accesstokendt),now)
  if (parseTime(state.accesstokendt)<new Date()) return true
  console.log('accesstokendt时间有效')
  */
  if (IsBlank(state.openid)) state.openid = state.accesstoken
  state.isneedauthlogin = false
  return false
}

/**
 * 更改本地token
 */
export const NewLocalToken = (state) => {
  const $token = GetRandomStr()
  localStorage.setItem('oldtoken', state.token)
  state.oldtoken = state.token
  state.token = $token
  localStorage.setItem('token',$token)
  
  return $token
}

/**
 * 更换本地token
 */
 export const IsLocalBindInfo = () => {
  var isBind = false
  console.log('islocalbindinfi ')
  return isBind
}


/**
 * 获取有本地的localtoken,是否已经绑定信息undefined
 */
 export const IsBlank = (object) => {
  if (object === undefined) return true
  if (object === null) return true
  if (object === '' || object === 'undefined' || object === 'null') return true
  return false
}

/**
 * 获取浏览器类型
 */
 export const GetBrowserType = () => {
  var ua = window.navigator.userAgent.toLowerCase()
    // // window.alert(ua)
    if (ua.indexOf('micromessenger') !== -1) {  // 说明是微信浏览器
      if (ua.indexOf('wxwork') !== -1) { // 说明是企业微信
        // // window.alert('企业微信客户端')
        // state.typekey = 'qywx'
        // // window.alert('企业微信客户端')
        // this.$store.state.typekey = 'qywx'
        return 1
      } else {
        // // window.alert('微信客户端')
        // this.$store.state.typekey = 'wechat'
        return 0
      }
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
       // this.$store.state.typekey = 'alipay'
       return 2
    }

    // // window.alert('未知客户端' + ua)
    return -1
  }

export function isValidDate (date) {
  return date instanceof Date && !isNaN(date.getTime())
}

export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  if (isValidDate(date) === false) return ''
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}