import { createApp } from 'vue';
import App from './App';
import DemoBlock from './components/DemoBlock';
import DemoSection from './components/DemoSection';
import { router } from './router';
import 'vant/lib/index.css';
import vuexI18n from 'vuex-i18n'
import { Lazyload } from 'vant'
import store from './store'

const app = createApp(App);
 
app.use(vuexI18n);
app.use(Lazyload);
app.use(router).component(DemoBlock.name, DemoBlock).component(DemoSection.name, DemoSection);
app.use(store)
app.mount('#app');
