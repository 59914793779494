<template>
  <div id="app">
    <!--
    <router-view v-slot="{ Component }">
      <keep-alive :include="includeList">
        <component :is="Component"></component>
      </keep-alive>
    </router-view>
    -->
    <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"  v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <component :is="Component"  v-if="!$route.meta.keepAlive"/>
  </router-view> 
  </div>
</template>
<!--
<script>
//使用动态include解析
//app.vue文件

  export default {
    name: 'App',
    data(){
      return {
        includeList : []
      }
    },
    watch:{
      $route(to) {
        //监听路由变化，把配置路由中keepAlive为true的name添加到include动态数组中
        const that = this;
        if(to.meta.keepAlive && that.includeList.indexOf(to.name) === -1){
          that.includeList.push(to.name);
          console.log('这个包含了', to.name)
        }
      }
    }
  }
</script>
-->
<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
</style>